const common = {
  'common.content-empty': '(No data)',
  'common.content-hi': 'Good',
};

const topNavDropdownMenu = {
  'common.top-nav-dropdown-menu.account': 'Account',
  'common.top-nav-dropdown-menu.change-password': 'Change Password',
  'common.top-nav-dropdown-menu.support': 'Support',
  'common.top-nav-dropdown-menu.log-out': 'Log out',
};

const langOptions = {
  'common.lang-options.vi': 'Vietnamese',
  'common.lang-options.en': 'English',
  'common.lang-options.ja': 'Japanese',
};

const sidebarMain = {
  'common.sidebar-main.dashboard': 'Dashboard',
  'common.sidebar-main.dashboard.home': 'Home',
  //
  'common.sidebar-main.agencies': 'Agency',
  //
  'common.sidebar-main.posts': 'Posts',
  'common.sidebar-main.posts.all-posts.article': 'All posts',
  'common.sidebar-main.posts.all-posts.product': 'All products',
  'common.sidebar-main.posts.all-posts.course': 'All course',
  'common.sidebar-main.posts.add-new.article': 'Write a new article',
  'common.sidebar-main.posts.add-new.product': 'New product',
  'common.sidebar-main.posts.add-new.course': 'New course',
  'common.sidebar-main.posts.post-category': 'Post Category',
  'common.sidebar-main.posts.product-category': 'Product Category',
  'common.sidebar-main.posts.course-category': 'Course Category',
  'common.sidebar-main.posts.post-data-group': 'Data Group',
  'common.sidebar-main.posts.post-article-group': 'Article Group',
  'common.sidebar-main.posts.product-trademark': 'Trademark',
  'common.sidebar-main.posts.product-article-group': 'Product Group',
  'common.sidebar-main.posts.course-trademark': 'Trademark',
  'common.sidebar-main.posts.course-article-group': 'Course Group',
  'common.sidebar-main.posts.product-discount': 'Discount',
  'common.sidebar-main.posts.course-discount': 'Discount',
  'common.sidebar-main.posts.product-order': 'Order',
  //
  'common.sidebar-main.media': 'Media',
  'common.sidebar-main.media.media-library': 'Library',
  'common.sidebar-main.media.add-new': 'Add New',
  //
  'common.sidebar-main.users': 'Members',
  'common.sidebar-main.users.list': 'All users',
  'common.sidebar-main.users.clients': 'All clients',
  'common.sidebar-main.users.role': 'Role',
  'common.sidebar-main.users.permissions': 'Permissions',
  //
  'common.sidebar-main.settings': 'Settings',
  'common.sidebar-main.settings.dynamic': 'Dynamic settings',
  'common.sidebar-main.settings.smtp': 'SMTP',
  'common.sidebar-main.settings.general': 'Config',
  'common.sidebar-main.settings.script': 'Code header & tracker',
  'common.sidebar-main.settings.social': 'Social',
  'common.sidebar-main.settings.copyright': 'Copyright',
  'common.sidebar-main.settings.company': 'Company',
  'common.sidebar-main.settings.email': 'Email ',
  'common.sidebar-main.settings.redirect': 'Redirect',
  //
  'common.sidebar-main.appearance': 'Appearance',
  'common.sidebar-main.appearance.menu': 'Menus',
  'common.sidebar-main.appearance.slider': 'Slider',
  //
  'common.sidebar-main.widgets': 'Widgets',
  //
  'common.sidebar-main.post-type': 'Post Type',
  //
  'common.sidebar-main.languages': 'Languages',
  //
  'common.sidebar-main.comment': 'Comment',
  //
  'common.sidebar-main.feedback': 'Feedback',
  //
  'common.sidebar-main.seo': 'SEO',
  //
  'common.sidebar-main.warehouse': 'Warehouse',
  'common.sidebar-main.warehouse-change': 'Import and Export',
  'common.sidebar-main.supplier': 'Supplier',
  //
  'common.sidebar-main.rating': 'Rating',
};

const label = {
  'common.label.email': 'Email',
  'common.label.password': 'Password',
  'common.label.captcha': 'Captcha',
};

const errorMsgInput = {
  'common.error-msg-input.email-require': 'Please enter the email address!',
  'common.error-msg-input.email-format': 'Your email address is not valid!',
  'common.error-msg-input.email-exist': 'Your email address has existed! Please enter another email address.',
  //
  'common.error-msg-input.password-require': 'Please enter a password!',
  'common.error-msg-input.password-length-5': 'The password has at least 5 characters!',
  //
  'common.error-msg-input.captcha-require': 'Please enter the captcha!',
  'common.error-msg-input.captcha-incorrect': 'Incorrect captcha!',
  //
  'common.error-msg-input.hotline-incorrect': 'Hotline must include 10 characters!',
  'common.error-msg-input.phone-incorrect': 'Phone number must include 10 characters!',
  //
  'common.error-msg-input.key-require': 'Please enter the lock!',
  //
  'common.error-msg-input.name-require': 'Please enter the full name!',
  'common.error-msg-input.name-length-min-2': 'At least 2 characters!',
  'common.error-msg-input.name-length-max-200': 'Maximum of 200 characters!',
  //
  'common.error-msg-input.title-require': 'Please enter the title!',
  'common.error-msg-input.slug-require': 'Please enter the slug!',
  //
  'common.error-msg-input.group-require': 'Please choose at least 1 option!',
  //
  'common.error-msg-input.image-require': 'Please download pictures!',
  'common.error-msg-input.image-size': 'The image you choose is too big! Please choose another image.',
  //
  'common.error-msg-input.list-image-require': 'Upload at least 1 picture!',
  //
  'common.error-msg-input.require': 'Please fill out this field!',
  //
  'common.error-msg-input.role-name-require': 'Please enter the role name!',
  'common.error-msg-input.role-name-length-min-2': 'At least 2 characters!',
  'common.error-msg-input.role-name-length-max-20': 'Maximum 20 characters!',
  //
  'common.error-msg-input.role-description-require': 'Please enter the description for the role!',
  'common.error-msg-input.role-description-length-max-255': 'Maximum 255 characters!',
  //
  'common.error-msg-input.role-permissions-require': 'Please select at least one permission!',
  //
  'common.error-msg-input.permission-user-require': 'Please select users!',
  'common.error-msg-input.permission-roles-require': 'Please choose at least 1 role!',
};

const rowHeadingPage = {
  'common.row-heading-page.dropdown-mobile.title': 'More',
  'common.row-heading-page.order-by.title': 'Sorted by',
  'common.row-heading-page.order-by.name': 'Name',
  'common.row-heading-page.order-by.status': 'Status',
  'common.row-heading-page.order-by.date': 'Date',
  'common.row-heading-page.order-by.style': 'Style',
  'common.row-heading-page.order-by.money': 'Money',
  'common.row-heading-page.order-by.supplier': 'Supplier',
  'common.row-heading-page.order-by.user': 'User',
  'common.row-heading-page.order-by.warehouse': 'Warehouse',
  'common.row-heading-page.order-by.province': 'Province',
  'common.row-heading-page.order-by.sort': 'Sort',
  'common.row-heading-page.order-by.titleS': 'Title',
  'common.row-heading-page.order-by.key': 'Key',
  'common.row-heading-page.order-by.code': 'Code',
  'common.row-heading-page.order-by.price': 'Price',
  'common.row-heading-page.order-by.number': 'Number',
  'common.row-heading-page.order-by.in_stock': 'In stock',
  'common.row-heading-page.order-by.export': 'Export',
  'common.row-heading-page.order-by.expiry': 'Expiry',
  'common.row-heading-page.order-by.expired': 'Expired',
  'common.row-heading-page.order-by.revenue': 'Revenue',
  'common.row-heading-page.order-by.profit': 'Profit',
  'common.row-heading-page.sort-by.title': 'Order by',
  'common.row-heading-page.sort-by.asc': 'Ascending',
  'common.row-heading-page.sort-by.desc': 'Decrease',
  'common.row-heading-page.search.placeholder': 'Search...',
  'common.row-heading-page.view.empty': 'No data',
  'common.row-heading-page.view.viewing-title': 'Watching',
  'common.row-heading-page.view.total-title': 'Total',
  'common.row-heading-page.view.total-title.unit': 'Item(s)',
};

const notification = {
  'common.notification.title-basic': 'Notification',
};

const button = {
  'common.button.back': 'Back',
  'common.button.load-more': 'Load more',
  'common.button.loading': 'Loading',
  'common.button.add': 'Add',
  'common.button.delete': 'Delete',
  'common.button.confirm': 'Confirm',
  'common.button.cancel': 'Cancel',
  'common.button.complete': 'Complete',
  'common.button.edit': 'Edit',
  'common.button.save-change': 'Save change',
  'common.button.cancel-change': 'Cancellate',
  'common.button.upload-image': 'Upload photo',
  'common.button.reload-page': 'Reload page',
};

const column = {
  'common.column.key': 'Key',
  'common.column.title': 'Title',
  'common.column.sort': 'Sort',
  'common.column.action': 'Action',
  'common.column.list-image': 'Photo list',
  'common.column.slug': 'Slug',
  'common.column.image': 'Image',
  'common.column.style': 'Style',
  'common.column.group-title': 'Data group name',
  'common.column.group-title-1': "$x's name",
  'common.column.group-product-title': 'Product group name',
  'common.column.group-product-title-1': "$x's name",
  'common.column.all': 'All',
  'common.column.layout': 'Layout',
  'common.column.category-parent': 'Category Father',
  'common.column.start-date': 'Start day',
  'common.column.start-end': 'End date',
  'common.column.status': 'Status',
  'common.column.status.disabled': 'Disabled',
  'common.column.status.enabled': 'Enabled',
  'common.column.id': 'ID',
  'common.column.name': 'Full name',
  'common.column.email': 'Email',
  'common.column.gender': 'Gender',
  'common.column.gender.male': 'Male',
  'common.column.gender.female': 'Female',
  'common.column.gender.unspecified': '---',
  'common.column.birthday': 'Birthday',
  'common.column.phone': 'Phone number',
  'common.column.role': 'Role',
  'common.column.password': 'Password',
  'common.column.zalo': 'Zalo',
  'common.column.facebook': 'Facebook',
  'common.column.address': 'Address',
  'common.column.avatar': 'Avatar',
  'common.column.roles': 'Roles',
  'common.column.role-name': 'Role name',
  'common.column.description': 'Description',
  'common.column.permissions': 'Permissions',
  'common.column.user': 'Choose a member',
};

const uploadMedia = {
  'common.upload-media.browse': 'Browse',
  'common.upload-media.drag': 'or drag and drop here',
  'common.upload-media.success': 'Success',
};

export default {
  ...common,
  ...topNavDropdownMenu,
  ...langOptions,
  ...sidebarMain,
  ...label,
  ...errorMsgInput,
  ...rowHeadingPage,
  ...notification,
  ...uploadMedia,
  ...button,
  ...column,
};
